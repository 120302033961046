<template>
  <div class="main-page">
    <header-bar></header-bar>
    <router-view></router-view>
    <footer-component></footer-component>
  </div>
</template>

<script>
import headerBar from "@components/headerBar";
import footerComponent from "@components/footer";

export default {
  name: "",
  components: {
    headerBar,
    footerComponent,
  },
  data() {
    return {
    };
  },
  methods: {
  },
  mounted() {},
  watch: {},
};
</script>

<style lang="less" scoped>
.main-page {
  width: 100%;
  height: 100%;
}
</style>